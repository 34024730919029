<template>
	<div class="p-register-consumer">
		<div class="item">
			<v-input placeholder="请输入手机号" v-model="form.mobile" clearable/>
			<v-button class="vercode" type="text" :disabled="timer.value!=-1" @click="getCode()">{{timer.value==-1?'获取验证码':`${timer.value}秒后重新获取`}}</v-button>
		</div>
		<div class="item">
			<v-input type="tel" placeholder="请输入验证码" v-model="form.verifyCode" clearable/>
		</div>
		<div class="item">
			<v-button class="login" type="primary" @click="register(form)" pill full>授权登录</v-button>
		</div>
	</div>
</template>

<script>
export default {
  data() {
    return {
      disabled: false,
      timeout: 60,
      timer: {
        id: -1,
        value: -1,
      },
      form: {
        party: this.$route.query.party,
        mobile: "",
        verifyCode: "",
      },
    };
  },
  methods: {
    start(value = 120) {
      this.timer.value = value;
      this.timer.id = setInterval(
        () => this.timer.value-- <= 0 && this.stop(),
        1000
      );
    },
    stop() {
      clearInterval(this.timer.id);
      this.timer.value = -1;
      this.timer.id = -1;
    },
    async getCode() {
      const errors = new Errors({
        mobile: await Object.validate(this.form.mobile, [
          "请填写手机号",
          { regexp: /^1\d{10}$/gm, message: "手机号的格式不正确" },
        ]),
      });
      if (errors.length) {
        this.$message.warn(errors[0]);
      } else {
        try {
          // await this.$apis["/注册/验证手机号"]({ alias: this.form.mobile });
          await this.$apis["/注册/获取验证码"]({ mobile: this.form.mobile });
          this.$message.success("验证码已发送，注意查收");
          this.start();
        } catch (e) {}
      }
    },
    async register(form) {
      this.disabled = true;
      await this.$apis["/注册/消费端"](form);
      this.disabled = false;
      location.replace(
        `${location.origin.replace(
          "register",
          "passport"
        )}/passport-server/oauth/wechat/authorize?${this.$route.query.origin}`
      );
      // if ("click" in this.$listeners) {
      //   this.$listeners.click(form, () => {
      //     this.disabled = false;
      //   });
      // } else {
      //   this.$action.user
      //     .register(Object.assign({}, form, this.$route.query))
      //     .then(() => {
      //       this.disabled = false;
      //       this.$router.replace({
      //         path: ["1706081809540003"].includes(
      //           this.$route.params.orgMemberId
      //         )
      //           ? `/${this.$route.params.orgMemberId}/complete`
      //           : this.$route.query.path ||
      //             `/${this.$route.params.orgMemberId}/home`,
      //         query: this.$route.query
      //       });
      //     })
      //     .catch(e => {
      //       this.disabled = false;
      //       e[0] && this.$message.error(e[0]);
      //     });
      // }
    },
  },
  created() {
    // if (this.$session.auth) {
    //   let { start, mobile } = this.$session.auth,
    //     timeout = this.timeout - parseInt((Date.now() - start) / 1000);
    //   if (start && timeout >= 0) {
    //     this.form.mobile = mobile;
    //     this.start(timeout);
    //   } else {
    //     this.stop();
    //   }
    // }
  },
  destroyed() {
    this.stop();
  },
};
</script>

<style lang="less">
.p-register-consumer {
  > .item {
    display: flex;
    // padding: 10px;
    margin: 20px 15px;
    align-items: center;
    background-color: #ffffff;
    > .v-input {
      flex: 1;
      font-size: 16px;
      border-radius: 0px;
      // input {
      //   border-radius: 0px;
      // }
    }
    > .vercode {
      color: #41ac76;
      margin-left: 10px;
      border-radius: 16px;
      border-color: #41ac76;
    }
    > .login {
      width: 100%;
      display: block;
      font-size: 16px;
      line-height: 32px;
      border-radius: 16px;
    }
  }
}
</style>
